import {
    SET_DEFAULT_PROVINCE
} from '../constants/user';

const initState = {
    province: null
}

const User = (state = initState, action) => {
    switch (action.type) {
        case SET_DEFAULT_PROVINCE:
            return {
                ...state,
                province: action.payload
            }
        default:
            return state;
    }
}

export default User