import {getAppAdsApi, getCategoryApi} from "../../api/api";
import {useEffect, useState} from "react";
import {Spin} from "antd";
import Style from "../../assets/styles/home.module.css";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import InfiniteScroll from 'react-infinite-scroll-component';
import Ads from "../../components/ads";
import HomeLayout from "../../template/home";
import Categories from "../../components/categories";

const Home = () => {

    const [ads, setAds] = useState([]);
    const [page, setPage] = useState(1);
    //const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(()=>{
        getCategory();
    },[]);

    useEffect(()=>{
        getAppAds(page);
    },[page]);

    const getAppAds = async (p, clean) => {
        //setLoading(true)
        await getAppAdsApi(p).then((res)=>{
            if(clean){
                setAds(res.data)
            } else {
                setAds([...ads, ...res.data]);
            }
            //setLoading(false)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const getCategory = async () => {
        await getCategoryApi().then((res)=>{
            setCategories(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const fetchData = () => {
        setTimeout(()=>{
            setPage(prevPage => prevPage + 1);
        },2000)
    }

    return (
        <HomeLayout>
            <Categories
                home={true}
                categories={categories}
            />

            <InfiniteScroll
                className={Style.AdsItems}
                dataLength={ads.length}
                next={fetchData}
                hasMore={true}
                loader={<div
                    className="d-flex align-items-center justify-content-center"
                    style={{margin: "10px 0"}}
                ><Spin/></div>}
                endMessage={<p>تمام</p>}
            >
                <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 2}}
                >
                    <Masonry
                        gutter="10px"
                    >
                        {ads?.map((Ad, Index)=>(
                            <Ads
                                key={Index}
                                Ad={Ad}
                            />
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            </InfiniteScroll>
        </HomeLayout>
    )
}

export default Home;