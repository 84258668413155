import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./redux/store";
import { ConfigProvider } from 'antd';
import {notification} from "antd";
import "./assets/styles/common.css";
import "./assets/styles/line-awesome.min.css";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import faIR from "antd/lib/locale/fa_IR";

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js')
            .then(registration => {
                console.log('Service Worker registered:', registration);
            })
            .catch(error => {
                console.error('Service Worker registration failed:', error);
            });
    });
}

const condition = navigator.onLine ? 'online' : 'offline';
if (condition === 'offline') {
    notification.error({
        message: "Error",
        description: "No Internet Connection"
    })
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider
                direction="rtl"
                locale={faIR}
                theme={{
                    token:
                        {
                            fontFamily: "IranSans",
                            colorPrimary: "#f55"
                        }
                }}
            >
                <App/>
            </ConfigProvider>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
