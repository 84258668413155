import Home from "./pages/home";
import ViewAds from "./pages/view/[ads_id]";
import BrowseAds from "./pages/browse";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

function App() {

    const Routes= createBrowserRouter([
        {
            path: "/",
            element: <Home />
        },
        {
            path: "/view/:ads_id",
            element: <ViewAds />
        },
        {
            path: "/browse",
            element: <BrowseAds />,
            children: [
                {
                    path: "/browse/:province",
                    element: <BrowseAds/>
                },
                {
                    path: "/browse/:province/:category",
                    element: <BrowseAds/>,
                },
                {
                    path: "/browse/:province/:category/:subcat",
                    element: <BrowseAds/>,
                },
                {
                    path: "/browse/:province/:category/:subcat/:subsubcat",
                    element: <BrowseAds/>,
                }
            ]
        }
    ]);

  return (
      <RouterProvider router={Routes} />
  );
}

export default App;
